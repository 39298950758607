var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Slick',{staticClass:"advantages",attrs:{"dots":true,"arrows":false,"responsive":[
    {
      breakpoint: 10000000,
      settings: 'unslick'
    },
    {
      breakpoint: 768,
      settings: {
        variableWidth: true
      }
    }
  ]}},_vm._l((_vm.advantages),function(ref,index){
  var title = ref.title;
  var desc = ref.desc;
return _c('div',{key:index,staticClass:"advantage advantages__item"},[_c('h4',{staticClass:"advantage__title",domProps:{"innerHTML":_vm._s(title)}}),_c('p',{staticClass:"advantage__desc"},[_vm._v(" "+_vm._s(desc)+" ")])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }