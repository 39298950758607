<template>
  <Slick
    class="advantages"
    :dots="true"
    :arrows="false"
    :responsive="[
      {
        breakpoint: 10000000,
        settings: 'unslick'
      },
      {
        breakpoint: 768,
        settings: {
          variableWidth: true
        }
      }
    ]"
  >
    <div
      v-for="({ title, desc }, index) in advantages"
      :key="index"
      class="advantage advantages__item"
    >
      <h4 class="advantage__title" v-html="title"></h4>
      <p class="advantage__desc">
        {{ desc }}
      </p>
    </div>
  </Slick>
</template>

<script>
import './advantages.scss';

export default {
  name: 'Advantages',
  data() {
    return {
      advantages: [
        {
          title: '100%',
          desc: 'подлинные сведения — данные из открытых источников'
        },
        {
          title: '85+',
          desc: 'субъектов РФ - онлайн отчет из любого региона страны'
        },
        {
          title: '150 ₽',
          desc: 'одна из самых низких цен детального отчета'
        },
        {
          title: '<span>от</span> 15 мин',
          desc: ' до 72 ч - время ожидания сведений'
        }
      ]
    };
  }
};
</script>
